<!-- @Auther: 杨鹏飞-->
<!-- @Date: 2022/8/26 -08 - 26 -10:29 上午-->
<!-- @Description: -->

<template>
  <a-layout class="h-screen">
    <a-layout-sider theme="dark" style="width: 400px">
      <div class="logo" :title="store.compname">
        {{ store.compname || "version" }}: 1.1.1
      </div>
      <a-menu
        :openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        mode="inline"
        @click="menuClick"
      >
        <Side :menuList="list" />
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <div></div>
        <a-dropdown>
          <div class="userinfo">
            <a-avatar
              class="curs"
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            />
            <span class="username">{{ username }}</span>
          </div>
          <template #overlay>
            <a-menu @click="headerClick">
              <a-menu-item key="1">退出登录</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-layout-header>
      <a-layout-content>
        <div class="h-full">
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import Side from "./components/Side.vue";
import { menulist } from "@/router/menu.js";
import { useUserStore } from "@/store/user";

const router = useRouter();
const list = reactive(menulist);
const openKeys = ["/Admixtures"];
const selectedKeys = ref([window.location.pathname]);
const username = localStorage.getItem("username");
const menuClick = (e) => {
  router.push(e.item.index);
};

const { store, loginOut: login_out } = useUserStore();
const loginOut = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("token_type");
  localStorage.removeItem("role_name");
  localStorage.removeItem("username");
  localStorage.removeItem("vuex");
  login_out()
    .then(() => {
      router.push("/login");
    })
    .catch(console.error);
};

const headerClick = ({ key }) => {
  switch (key) {
    case "1":
      loginOut();
      break;
    default:
      console.log("error");
  }
};
</script>

<style lang="less" scoped>
.h-screen {
  height: 100vh;
  width: 100vw;
  .logo {
    height: 32px;
    margin: 16px 16px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #fff;
  }
  .h-full {
    height: 100%;
    padding: 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
}
</style>

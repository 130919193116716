export const menulist = [
  // {
  //   key: "1",
  //   title: "首页",
  //   icon: "HomeOutlined",
  //   path: "/index",
  // },
  {
    key: "16",
    title: "外加剂调配",
    icon: "SettingOutlined",
    path: "/Admixtures",
    children: [
      {
        key: "16-1",
        path: "/Admixtures/Brand",
        title: "厂家管理",
      },
      {
        key: "16-2",
        path: "/Admixtures/Drive",
        title: "设备管理",
      },
      {
        key: "16-2",
        path: "/Admixtures/Live",
        title: "设备列表",
      },
    ],
  },
];
